// locationSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Service from '../http';
const services = new Service();

// Define the initial state
const initialState = {
  countries: [],
  states: [],
  cities: [],
  loading: false,
  error: null,
};

// Define async thunks to fetch data from APIs
export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
  const response = await services.get('country');
  return response.data;
});

export const fetchStatesByCountry = createAsyncThunk('states/fetchStatesByCountry', async (countryId) => {
  const response = await services.get(`states/country?id=${countryId}`);
  return response.data;
});

export const fetchCitiesByState = createAsyncThunk('cities/fetchCitiesByState', async (stateId) => {
  const response = await services.get(`cities/state?id=${stateId}`);
  return response.data;
});

// Create a slice
const locationSlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetchCountries pending and fulfilled actions
    builder.addCase(fetchCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.countries = action.payload;
    });
    // Handle fetchStatesByCountry pending and fulfilled actions
    builder.addCase(fetchStatesByCountry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStatesByCountry.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.states = action.payload;
    });
    // Handle fetchCitiesByState pending and fulfilled actions
    builder.addCase(fetchCitiesByState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCitiesByState.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.cities = action.payload;
    });
  },
});

export default locationSlice.reducer;

// Selectors
export const selectCountries = (state) => state.country.countries;
export const selectStates = (state) => state.country.states;
export const selectCities = (state) => state.country.cities;
export const selectLoading = (state) => state.country.loading;
export const selectError = (state) => state.country.error;
