// tennisTournamentGradePointerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../../http'; // Update this import path to the correct location of your http.js file

const initialState = {
   gradePointList: [],
   loading: false,
   error: null,
};

export const fetchTournamentsGradePoint = createAsyncThunk('tennisTournament/fetchTournamentsGradePoint', async (_, thunkAPI) => {
   try {
      const service = new Service();

      const response = await service.get('tennis-tournament-grade-point/listview');

      

      return response.data;

   } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
   }
});

const tennisTournamentGradePointerSlice = createSlice({
   name: 'tournamentGradePointer',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchTournamentsGradePoint.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchTournamentsGradePoint.fulfilled, (state, action) => {
            state.loading = false;
            state.gradePointList = action.payload;
         })
         .addCase(fetchTournamentsGradePoint.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default tennisTournamentGradePointerSlice.reducer;
