import React from "react";
import { useSelector } from "react-redux";
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../public/animations/loader.json'; 
export default function Loader({ Obj }) {
  const frontend = useSelector(state => state.frontend.customizationData.logo);

  return (
    <div className="loader">
      {/* {frontend ? <img src={frontend} alt="logo" /> : <img src="../public/images/logo.png" alt="default logo" />} */}
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: '150px', width: '150px' }}
      />
      <h5>Loading...</h5>
    </div>
  );
}
