import React from "react";
import { Animated } from "react-animated-css";
import { MdOutlineClose } from "react-icons/md";

export default function LtModal({ isOpen, onClose, heading, children, width = 500 }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100000000] flex justify-center bg-black bg-opacity-50 pt-5 py-[20px] overflow-auto">
      <div className={`s max-w-[calc(100%-30px)]`} style={{ width: width }}>
        <Animated animationIn="zoomIn fadeIn" animationOut="zoomOut fadeOut" isVisible={true} animationInDuration={300} animationOutDuration={300}>
          <div className="max-h-[calc(100vh-70px)]_ w-full rounded-lg bg-white shadow-lg">
            {heading&&<div className="flex items-center justify-between border-b p-3">
              <h2 className="mb-0 text-lg font-semibold">{heading}</h2>
              <button onClick={onClose} className="h-[30px] w-[30px] rounded-3xl border-0 bg-slate-300 text-gray-500 hover:text-gray-700">
                <MdOutlineClose />
              </button>
            </div>}
            <div className="border-top p-4">{children}</div>
          </div>
        </Animated>
      </div>
    </div>
  );
}
