import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import Service from "../http";
import LocalStorage from "../components/LocalStorage";
import { useNavigate } from "react-router-dom";
import { themeColor1, themeColor2 } from "../components/utils";

export const themeSlice = createSlice({
  name: "themeColors",
  initialState: {themeColor1 : themeColor1() ? themeColor1() : '', themeColor2: themeColor2() ? themeColor2() : ''},
  reducers: {
    themeState: (state, action) => {
      localStorage.setItem('theme', action.payload.colors)
      return({
        ...state, themeColor1: action.payload.colors[0], themeColor2: action.payload.colors[1]
  })}
  }
});

export const { themeState } = themeSlice.actions;
export default themeSlice.reducer;
