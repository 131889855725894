import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCities = createAsyncThunk("citylist/getCities", async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + "/cities");
    return response.data;
  } catch (error) {
    console.error(error);
  }
});

const citySlice = createSlice({
  name: "citylist",
  initialState: {
    cities: {},
    isLoading: false,
    hasError: false
  },
  extraReducers: (builder) => {
    builder.addCase(getCities.pending, (state, action) => {
      state.isLoading = true;
      state.hasError = false;
    })
      .addCase(getCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.isLoading = false;
        state.hasError = false
      })
      .addCase(getCities.rejected, (state, action) => {
        state.hasError = true
        state.isLoading = false;
      })
  }
});

export default citySlice.reducer;