// centerSportSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../../http"; // Update this import path to the correct location of your http.js file
const service = new Service();

const initialState = {
  centerSport: [],
  totalPageCount: 0,
  loading: false,
  error: null,
};

export const fetchCenterSports = createAsyncThunk(
  "center/fetchCenterSport",
  async ({ centerId, sportId, page_no }, thunkAPI) => {
    try {

      const response = await service.get(`academy-center-sports/listview?center_id=${centerId}&sport_id=${sportId}&page_no=${page_no}`);

      // Check the response data in the console

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const centerSportSlice = createSlice({
  name: "centerSports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCenterSports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCenterSports.fulfilled, (state, action) => {
        state.loading = false;
        state.centerSport = action.payload.content;
        state.totalPageCount = action.payload.totalPages;
      })
      .addCase(fetchCenterSports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default centerSportSlice.reducer;
