import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  path:"some address"
}

const getPathSlice = createSlice({
  name: 'getAddressBar',
  initialState,
  reducers: {
    getPath: (state, action) => {
      return action.payload;
    },
  },
});

export default getPathSlice.reducer;
