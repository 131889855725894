import React, { useEffect } from "react";

function NotificationComponent({ body, icon, title }) {
  function notifyMe() {
    if (window.Notification.permission !== "granted") window.Notification.requestPermission();
    else {
      var notification = new window.Notification(title, {
        icon,
        body,
        requireInteraction: true, // Makes the notification persistent
      });
    }
  }

  useEffect(() => {
    if (!window.Notification) {
      alert("Desktop notifications not available in your browser. Try Chromium.");
      return;
    }

    if (window.Notification.permission !== "granted") window.Notification.requestPermission();

    notifyMe();
  }, []);
  return <></>;
}

export default NotificationComponent;
