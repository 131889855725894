import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import LanguageDetector from "i18next-browser-languagedetector";
import { BiCookie } from "react-icons/bi";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "th", "vi", "hi"],
    fallbackLng: "en",
    detection: {
      order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const handleMouseMove = event => {
  const mouseDot = document.getElementById("mouseDot");
  if (mouseDot) {
    window.requestAnimationFrame(() => {
      mouseDot.style.left = `${event.clientX}px`;
      mouseDot.style.top = `${event.clientY}px`;
    });
  }
};

document.addEventListener("mousemove", handleMouseMove);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <div>
          {/* <span
            id="mouseDot"
            style={{
              position: "fixed",
              background: "rgba(0,0,0,0.3)",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
              pointerEvents: "none",
            }}></span> */}
          <App />
        </div>
      </BrowserRouter>
    </Provider>

    {/* <style jsx="true">{`
      #mouseDot {
        transition: all 500ms cubic-bezier(0, 0.655, 1, 0.31); 
        transition-timing-function: cubic-bezier(0, 0.655, 1, 0.31); 
      }
    `}</style> */}
  </React.StrictMode>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
