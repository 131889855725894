// usersCoachesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../../http'; // Update this import path to the correct location of your http.js file

const initialState = {
   coach: [],
   totalPageCount: 0,
   loading: false,
   error: null,
};

export const fetchCoaches = createAsyncThunk('users/fetchCoaches', async ({ centerId, sportId, page_no }, thunkAPI) => {
   try {
      const service = new Service();
      const queryString = `coach/listview?center_id=${centerId}&sport_id=${sportId}&page_no=${page_no}`;
      const response = await service.get(queryString);
      
      return response.data;
   } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
   }
});

const usersCoachesSlice = createSlice({
   name: 'coaches',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchCoaches.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchCoaches.fulfilled, (state, action) => {
            state.loading = false;
            state.coach = action.payload?.content;
            state.totalPageCount = action.payload?.totalPages;
         })
         .addCase(fetchCoaches.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default usersCoachesSlice.reducer;
