// Get the first theme color from the local storage or use a default value
export const themeColor1 = () =>
  localStorage.getItem("theme")
    ? localStorage.getItem("theme").split(",")[0]
    : "#F2B000";

// Get the second theme color from the local storage or use a default value
export const themeColor2 = () =>
  localStorage.getItem("theme")
    ? localStorage.getItem("theme").split(",")[1]
    : "#2F308C";
// console.log(localStorage.getItem('theme')&&localStorage.getItem('theme').split(','))
// Capitalize the first letter of each word in the input string
export const capitalizeFirstLetter = (string) => {
  return string
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Check if a given hexadecimal color is considered light or dark
export const wc_hex_is_light = (color) => {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  return brightness > 155;
};

// Get an array of weeks in the given year
export function getWeeksOfYear(year) {
  const weeks = [];
  let date = new Date(year, 0, 1); // Start with January 1st of the given year

  // Find the first Monday of the year
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() + 1);
  }

  const options = { month: "short", day: "2-digit", year: "numeric" };

  while (date.getFullYear() == year) {
    weeks.push({
      label: date.toLocaleDateString("en-US", options),
      value: date.toISOString().split("T")[0], // Get the date part of ISO string (YYYY-MM-DD)
    });

    date.setDate(date.getDate() + 7); // Move to the next week (Monday of the next week)
  }

  return weeks;
}

export function tableSerialNumber(
  index,
  currentPage = 1,
  itemsOnPage,
  defaultEntryCount = 25
) {
  return (
    (currentPage - 1) * (currentPage > 1 ? defaultEntryCount : itemsOnPage) +
    (index + 1)
  );
}

// Calculate the current date and get the year value three months later
const currentDate = new Date();
currentDate.setMonth(currentDate.getMonth() + 3);
const yearValueThreeMonthsLater = currentDate.getFullYear();

// Create an array of years from the current year to three months later
export function createRangeWithForLoop(start) {
  const range = [];
  for (let i = start; i <= yearValueThreeMonthsLater; i++) {
    range.push({ label: i+'' , value: i+'' });
  }
  return range;
}

// Convert a UTC date-time string to local date and time
export function convertToLocaleDateTime(utcDateTimeString) {
  // Create a new Date object using the UTC date string
  const dateObj = new Date(utcDateTimeString);

  // Convert the date to the local timezone
  const localDate = dateObj.toLocaleDateString();
  const localTime = dateObj.toLocaleTimeString();

  // Return the local date and time as an object
  return {
    localDate: localDate,
    localTime: localTime,
  };
}

export function removeDuplicates(inputString) {
  const inputArray = inputString.split(",");

  const uniqueArray = [...new Set(inputArray)];

  const resultArray = uniqueArray.filter((item) => item.trim() !== "");

  const resultString = resultArray.join(",");

  return resultString;
}

export function convertDateRange(dateRange) {
  const [startDateStr, endDateStr] = dateRange.split(" - ");

  const startDate = new Date(startDateStr);

  const endDate = new Date(endDateStr);

  const startDay = startDate.getDate();

  const startMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(
    startDate
  );

  const endDay = endDate.getDate();

  const endMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(
    endDate
  );

  const formattedDateRange = `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

  return formattedDateRange;
}

export function formatTimeWithSeconds(inputTime) {
  // Ensure the input time has a valid format (HH:mm)
  if (/^\d{2}:\d{2}$/.test(inputTime)) {
    const currentTime = new Date();
    const [hours, minutes] = inputTime.split(":");
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0); // Set seconds to 0
    const formattedTime = currentTime.toLocaleTimeString("en-US", {
      hour12: false, // Ensure 24-hour format
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return formattedTime;
  } else {
    return "Invalid time format";
  }
}

export function formatTime(time) {
  // Getting hours, minutes, and seconds from the provided Date object
  if(time){  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  // Formatting hours, minutes, and seconds to ensure they have two digits
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  // Constructing the hh:mm:ss format
  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
  }
}

export function shortDate(currentdate) {
  const date = new Date(currentdate)
  // Define an array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  // Get the day, month, and year from the Date object
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  
  // Format the date string
  const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;
  
  return formattedDate;
}


export function getDiscountedPrice(discount, price) {
  const discountedAmount = (discount / 100) * price;
  const discountedPrice = price - discountedAmount;
  return discountedPrice;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const addLeadingZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = addLeadingZero(date.getDate());
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = addLeadingZero(date.getMinutes());
  const seconds = addLeadingZero(date.getSeconds());

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  const timeString = `${addLeadingZero(hours)}:${minutes}:${seconds} ${ampm}`;

  return `${day} ${month}, ${year} ${timeString}`;
};

export function amount(number, separator = ",", decimalSeparator = ".") {
  return parseFloat(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    .replace(".", decimalSeparator);
}

export function formatPrice(price) {
  if (price == null || isNaN(price)) {
      return '';
  }
  const parts = price.toFixed(2).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

