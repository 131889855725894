// tournamentListSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../../http'; // Update this import path to the correct location of your http.js file

const initialState = {
   tournament: [],
   totalPageCount: 0,
   loading: false,
   error: null,
};

export const fetchTournament = createAsyncThunk(
   'tournament/fetchTournament', async (thunkAPI) => {
      try {
         const service = new Service();
         const queryString = `tennis-tournament/listview`;
         const response = await service.get(queryString);
         
         return response.data;
      } catch (error) {
         // If error.response exists and contains data, use it as the payload
         if (error.response && error.response.data) {
            return thunkAPI.rejectWithValue(error.response.data);
         } else {
            // If no structured error data is available, return a default error payload
            return thunkAPI.rejectWithValue({ message: 'An error occurred' });
         }
      }
   }
);

export const fetchTournamentDetails = createAsyncThunk(
   'tournament/fetchTournamentDetails', async (tournamentId, thunkAPI) => {
      try {
         const service = new Service();
         const queryString = `tennis-tournament/detail/${tournamentId}`;
         const response = await service.get(queryString);
         console.log('Tournament Details API Response:', response);
         return response.data;
      } catch (error) {
         if (error.response && error.response.data) {
            return thunkAPI.rejectWithValue(error.response.data);
         } else {
            return thunkAPI.rejectWithValue({ message: 'An error occurred' });
         }
      }
   }
);

const tournamentListSlice = createSlice({
   name: 'tournaments',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchTournament.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchTournament.fulfilled, (state, action) => {
            state.loading = false;
            state.tournament = action.payload.content;
            state.totalPageCount = action.payload.totalPages;
         })
         .addCase(fetchTournament.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         })

         .addCase(fetchTournamentDetails.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchTournamentDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.selectedTournament = action.payload; // Add a new property to store the selected tournament details
         })
         .addCase(fetchTournamentDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default tournamentListSlice.reducer;
