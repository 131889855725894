import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import Service from "../http";
import LocalStorage from "../components/LocalStorage";
import { notify } from "../components/Notification";

const services = new Service();
// Define initialstate
const initState = {
  is_fetching: false,
  is_error: false,
  is_success: false,
  error_message: "",
  user: {},
  user_type: "",
  access_token: "",
};

// Login users: Player, Coach, Academy etc.
export const loginRequestMember = createAsyncThunk(
  "login/requestMember",
  async({ loginURL, email, password }, thunkAPI) => {
    try {
      const response = await services.post(loginURL, { email, password });
      if (response.status) {
        sessionStorage.setItem("isLogin", "true");
        let resData = response.data;
        LocalStorage.setValue("usertype", resData.user_type);
        LocalStorage.setValue("userinfo", resData.access_token);
        LocalStorage.setValue("is_profile_completed", resData.is_profile_completed);
        LocalStorage.setValue("reset_required", resData.reset_required);
        LocalStorage.setValue("lastLogin", resData.last_login)
        localStorage.setItem("lastLogin", resData.last_login)

        console.log( resData.country )
        if (resData.profile_image !== null) {
          window.location.href = '/dashboard'
        } else {
          window.location.href = '/my-profile'
        }
        // window.location.href = '/dashboard'

        return resData;
      } else {
        notify(response.message[0].message);
        return thunkAPI.rejectWithValue(response.message[0].message);
      }
    } catch (err) {
      notify(err.message);
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const loginRequest = createAsyncThunk(
  "login/request",
  async ({ loginURL, email, email_or_id, password }, thunkAPI) => {

    // console.log(loginURL, email, email_or_id, password)
    try {
      const response = await services.post(loginURL, { email, email_or_id, password });
      if (response.status) {
        sessionStorage.setItem("isLogin", "true");
        let resData = response.data;
        LocalStorage.setValue("usertype", resData.user_type);
        LocalStorage.setValue("userinfo", resData.access_token);
        LocalStorage.setValue("is_profile_completed", resData.is_profile_completed);
        LocalStorage.setValue("reset_required", resData.reset_required);
        LocalStorage.setValue("lastLogin", resData.last_login)
        localStorage.setItem("lastLogin", resData.last_login)
        localStorage.setItem("countDown", new Date())
        resData?.country&&localStorage.setItem("country", JSON.stringify(resData?.country))
        setTimeout(()=>{
        if (resData.profile_image !== null) {
          window.location.href = '/dashboard'
        } else {
          window.location.href = '/my-profile'
          }
        },[300])

        return resData;
      } else {
        notify(response.message[0].message, 'success');
        return thunkAPI.rejectWithValue(response.message[0].message);
      }
    } catch (err) {
      notify(err.message,'error');
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const loginSlice = createSlice({
  name: "Login",
  initialState: initState,
  reducers: {
    clearState: (state) => {
      state.is_success = false;
      state.is_fetching = false;
      state.is_error = false;
      state.user = {};
      state.user_type = "";
      state.access_token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.is_fetching = true;
      })
      .addCase(loginRequest.fulfilled, (state, { payload }) => {
        state.is_fetching = false;
        state.is_error = false;
        state.is_success = true;
        state.error_message = "";
        state.user = payload;
        state.user_type = payload.user_type;
        state.access_token = payload.access_token;
      })
      .addCase(loginRequest.rejected, (state, { payload }) => {
        console.log("Rejected", payload);
        state.is_fetching = false;
        state.is_success = false;
        state.is_error = true;
        state.error_message = payload?.error || payload?.message || 'Error in login';
      })
      .addCase(loginRequestMember.pending, (state) => {
        state.is_fetching = true;
      })
      .addCase(loginRequestMember.fulfilled, (state, { payload }) => {
        state.is_fetching = false;
        state.is_error = false;
        state.is_success = true;
        state.error_message = "";
        state.user = payload;
        state.user_type = payload.user_type;
        state.access_token = payload.access_token;
        console.log(payload);
      })
      .addCase(loginRequestMember.rejected, (state, { payload }) => {
        console.log("Rejected", payload);
        state.is_fetching = false;
        state.is_success = false;
        state.is_error = true;
        state.error_message = payload.error || payload.message;
      });
  },
});

export const { clearState, loginAction, logoutAction } = loginSlice.actions;
export default loginSlice.reducer;
