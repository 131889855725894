import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getState = createAsyncThunk("statelist/getState", async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + "/states");
    return response.data;
  } catch (error) {
    console.error(error);
  }
});

const stateSlice = createSlice({
  name: "statelist",
  initialState: {
    states: {},
    isLoading: false,
    hasError: false
  },
  extraReducers: (builder) => {
    builder.addCase(getState.pending, (state, action) => {
      state.isLoading = true;
      state.hasError = false;
    })
      .addCase(getState.fulfilled, (state, action) => {
        state.states = action.payload;
        state.isLoading = false;
        state.hasError = false
      })
      .addCase(getState.rejected, (state, action) => {
        state.hasError = true
        state.isLoading = false;
      })
  }
});

export default stateSlice.reducer;