import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";
import doAPI from "../api";

// Define the initial state
const initialState = {
  stationList: [],
  loading: false,
  error: null,
};

// Define the asynchronous thunk to fetch station list data
export const fetchStationList = createAsyncThunk(
  "station/fetchStationList",
  async ({ page = "", isActive, centerId, sportId, entryCount=25 }, thunkAPI) => {
    try {
      const result = await doAPI.getData(
        `academy-station/academy?is_activated=${isActive}&limit=${entryCount}&page_no=${page}&center_id=${centerId}&sport_id=${sportId}`
      );

      const response = result.data;
      if (response.status) {
        return response.data;
        // } else {
        //   return thunkAPI.rejectWithValue({ error: response.message[0].message });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Create the station slice
const stationSlice = createSlice({
  name: "station",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStationList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStationList.fulfilled, (state, action) => {
        state.loading = false;
        state.stationList = action.payload;
      })
      .addCase(fetchStationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const stationActions = {
  ...stationSlice.actions,
  fetchStationList,
};

export default stationSlice.reducer;
