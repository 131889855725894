import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
// import MainSlider from "./MainSlider";
import { themeColor1 } from "../../components/utils";
import Loader from "../../components/Loader";
// import doAPI from "../../api";
// import AboutUs from "./FrontendComponents/Sections/AboutUs";
// import Coaches from "./FrontendComponents/Sections/Coaches";
// import Programs from "./FrontendComponents/Sections/Programs";
// import Players from "./FrontendComponents/Sections/Players";
// import HeroSection from "./FrontendComponents/MainSliders/HeroSection";
// import Slider from "react-slick";
// import Image from "../../components/Image";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
// import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Home() {
  // const [offset, setOffset] = useState(0);
  // const [faq, setFaq] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  // useEffect(() => {
  //   if (currentIndex < text.length) {
  //     const timeout = setTimeout(() => {
  //       setCurrentText(prevText => prevText + text[currentIndex]);
  //       setCurrentIndex(prevIndex => prevIndex + 1);
  //     }, delay);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [currentIndex, delay, text]);
  // useEffect(() => {
  //   setIsHide(window.location.href.includes("dlta.ewizpro.com") || window.location.href.includes("demo.ewizpro.com"));
  // }, []);

  // const [scrollY, setScrollY] = useState(0);
  // const [elementOffset, setElementOffset] = useState(0);
  // const blogRef = useRef(null);

  // const handleScroll = e => {
  //   setScrollY(window.scrollY);
  // const offset = blogRef.current.offsetTop;
  // setElementOffset(offset);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const frontend = useSelector(state => state?.frontend?.customizationData);
  const { loading } = useSelector(state => state?.frontend);

  // const { customize_frontend, programs } = frontend;

  // const { about_us, meet_our_players, meet_our_coaches, our_vision, meet_our_centers } = customize_frontend || {};

  // React.useEffect(() => {
  //   services &&
  //     services.get("player/listview").then((res) => {
  //       setPlayers(res?.data?.content);
  //     });
  // }, []);

  // React.useEffect(() => {
  //   try {
  //     doAPI.getData("academy-faq/listview").then(res => {
  //       const response = res.data;
  //       if (response.status) {
  //         setFaq(response?.data?.content);
  //       }
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, []);

  React.useEffect(() => {
    const url = window.location.href.split("?zoom=");
    if (url.includes("?zoom=")) {
      const param = url[url.length - 1];
      document.querySelector("body").style.zoom = param;
      document.querySelector("body").style.pointerEvents = "none";
    }
  }, []);

  useEffect(() => {
    const videoElement = document.getElementById("myVideo");
    if (videoElement) {
      videoElement.play().catch(error => {
        console.error("Error attempting to play", error);
      });
    }
  }, []);

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   fade: true,
  //   speed: 4000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   pauseOnHover: false,
  //   autoplay: true,
  //   autoplaySpeed: 6000,
  //   arrows: false,
  // };

  // const sliderData = useSelector(state => state.frontend?.customizationData?.frontend_sliders);

  return (
    <>
      {loading ? <Loader bg={themeColor1()} Obj={frontend?.logo} /> : ""}

      <div className="relative">
        <Header header={2} />
        <video
          id="videoElementId"
          className="slideImg h-svh w-full object-cover"
          poster="https://www.dltapi.ewizpro.com:9014/uploads/video/futurepro-video.mp4"
          autoPlay
          loop
          muted>
          <source src="https://www.dltapi.ewizpro.com:9014/uploads/video/futurepro-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <video
          id="videoElementId"
          className="slideImg h-svh w-full object-cover"
          poster="https://www.dltapi.ewizpro.com:9014/uploads/video/dlta-video.mp4"
          autoPlay
          loop
          muted>
          <source src="https://www.dltapi.ewizpro.com:9014/uploads/video/dlta-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        <div className="main_slider1">
          {/* <Slider {...settings}>
            {sliderData &&
              sliderData.map(i => (
                <div key={i.id} className="relative overflow-hidden">
                  {/* <Image
                    src={`${i.image_path}/${i.image_name}`}
                    className="slideImg  h-svh w-full object-cover"
                    alt={i.title}
                    imgPreview={`${i.image_path}/${i.image_name}?resize=30`}
                  /> 
                  <LazyLoadImage
                    className="slideImg  h-svh w-full object-cover"
                    src={`${i.image_path}/${i.image_name}?1300,1600`}
                    placeholderSrc={`${i.image_path}/${i.image_name}?resize=30,40`}
                    alt="Image Alt"
                  />
                </div>
              ))}
          </Slider> */}
          <div className="animate__animated animate__fadeRight absolute inset-0 flex h-full w-full flex-col items-center justify-center bg-[#0000008a] p-4 text-white ">
            <div className="text-center">
              {/* <h2 className="boxShadow-3xl typewriter mb-8 font-display text-3xl font-extrabold text-white sm:text-4xl md:text-6xl">
                FuturePro Sports
              </h2> */}
              <h2 className="mb-6 font-display text-3xl font-extrabold text-white sm:text-4xl md:text-6xl">
                {/* Passion for <Typewriter text="Excellence" delay={400} infinite /> */}
                Passion for <ReactTyped strings={["Excellence"]} typeSpeed={400} backDelay={100} loop />
                {/* Conquer the <ReactTyped strings={["Court"]} typeSpeed={400} backDelay={100} loop /> */}
              </h2>
              {/* <h5 className="mb-8 font-display text-base sm:text-lg md:text-3xl">Delhi Lawn Tennis Association</h5> */}
              <h5 className="mb-8 font-display text-base sm:text-lg md:text-3xl">FuturePro Sports</h5>
              <Button
                as={Link}
                to="/player-register"
                variant="outline"
                className="rounded-lg border border-white px-7 py-2 text-[18px] font-bold uppercase text-white hover:bg-[var(--accent-color)]">
                Join Us
              </Button>
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx="true">
          {`
            .main_slider1 {
              // margin-top: 80px;
            }
            @keyframes anim {
              from {
                transform: scale(1);
              }

              to {
                transform: scale(1.1);
              }
            }
            .main_slider1 .slideImg {
              animation-duration: 6s;
              animation-name: anim;
              animation-iteration-count: infinite;
              animation-direction: alternate;
            }
            .main_slider1 .slide-content {
              background: rgba(0, 0, 0, 0.5);
              background-image: url(./assets/images/tennisBallOutline.png);
              background-size: 400px;
              background-position: center;
              background-repeat: no-repeat;
            }
            .main_slider1 .slide-content > div {
              transform: translate(0, 200px);
              opacity: 0;
              transition: all 2s 1s ease-in-out;
            }

            .main_slider1 .slick-active .slide-content > div {
              transform: translate(0px, 0px);
              opacity: 1;
            }

            .main_slider1 .slick-arrow {
              position: absolute;
              z-index: 1;
              background: var(--base-color);
              height: 40px;
              width: 40px;
              border-radius: 8px;
              display: flex !important;
              align-items: center;
              justify-content: center;
            }
          `}
        </style>
        {/* <HeroSection sliderData={frontend?.frontend_sliders} />

        <AboutUs data={frontend} />

        <Coaches data={frontend} />

        <Programs data={frontend} />

        <Players data={frontend} /> */}
        {/* {!isHide && <MeetOurCoaches data={frontend} />}
        {!isHide && <MeetOurCenter data={frontend} />}
        {<Programs data={frontend} />}
        {!isHide && <Events />}
        {!isHide && <Stats />}
        {meet_our_players && meet_our_players.length > 0 ? <MeetOurPlayers1 data={frontend} /> : null}
        <Tournaments />

        <section id="about" className=" position-relative z-1  pb-[50px] md:pb-[200px] lg:mb-[-100px] lg:mt-[-130px]">
          <Container className="gx-3 flex flex-wrap ">
            <div className="mb-3 mt-[50px] w-[100%] md:mt-[250px] md:pe-[50px] lg:w-[50%]">
              <AboutUs data={frontend} player />
            </div>
            <div className="w-[100%] lg:w-[50%] lg:ps-[50px]">
              <OurVision data={frontend} />
            </div>
          </Container>
        </section>

        {!isHide && <MeetOurPlayers data={frontend} />}
        {!isHide && <Gallery />}

        {!isHide && <Blogs1 />}
        {!isHide && <Blogs />}
        {/* <div className="banner">
          <Image src={"/assets/images/red_ball_program.jpg"} className="w-100" />
        </div>

        {!isHide && <Testimonials2 />}
        {!isHide && <Faq data={faq} />}
        {!isHide && <Faq1 data={faq} />}
        {!isHide && <Testimonials1 />}
        <div ref={blogRef} className={elementOffset - 500 <= scrollY && "triggered"}>
          {!isHide && <Testimonials />}
        </div>
        {!isHide && <InstaGallery />}
        <FrontendFooter />
        <SocialBar variant={"variant1"} /> */}
      </div>
    </>
  );
}
// export const Typewriter = ({ text, delay, infinite }) => {
//   const [currentText, setCurrentText] = useState("");
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     let timeout;

//     if (currentIndex <= text.length) {
//       timeout = setTimeout(() => {
//         setCurrentText(prevText => prevText + text[currentIndex]);
//         setCurrentIndex(prevIndex => prevIndex + 1);
//       }, delay);
//     } else if (infinite) {
//       // ADD THIS CHECK
//       setCurrentIndex(0);
//       setCurrentText("");
//     }

//     return () => clearTimeout(timeout);
//   }, [currentIndex, delay, infinite, text]);

//   return <span>{currentText}</span>;
// };
