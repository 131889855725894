import React from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import PublicRoute from "./PublicRoute";
import Loader from "../components/Loader";

// Routes
const AdminRoute = lazy(() => import("./AdminRoute"));
const AcademyRoute = lazy(() => import("./AcademyRoute"));
const PlayerRoute = lazy(() => import("./PlayerRoute"));
const CoachRoute = lazy(() => import("./CoachRoute"));
const MemberRoute = lazy(() => import("./MemberRoute"));

export default function RouteFile() {
  const loginStatus = useSelector(state => state.login.user_type);
  const className = window.location.pathname !== "/" ? "player-login" : "homepage";

  return (
    <>
      {loginStatus === "player" ? (
        <Suspense fallback={<Loader />}>
          <div className={className}>
            <PlayerRoute />
          </div>
        </Suspense>
      ) : loginStatus === "coach" ? (
        <Suspense fallback={<Loader />}>
          <div className={className}>
            <CoachRoute />
          </div>
        </Suspense>
      ) : loginStatus === "academy" ? (
        <Suspense fallback={<Loader />}>
          <div className={className}>
            <AcademyRoute />
          </div>
        </Suspense>
      ) : loginStatus === "member" ? (
        <Suspense fallback={<Loader />}>
          <div className={className}>
            <MemberRoute />
          </div>
        </Suspense>
      ) : loginStatus === "administrator" ? (
        <Suspense fallback={<Loader />}>
          <div className={className}>
            <AdminRoute />
          </div>
        </Suspense>
      ) : (
        <PublicRoute />
      )}
    </>
  );
}
