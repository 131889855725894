import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "../http";

// Define the initial state
const initialState = {
  ticketData: [],
  ticketType: [],
  ticketSubType: [],
  loading: false,
  error: null,
};

// Define the asynchronous thunk to fetch ticket data
export const fetchTicketData = createAsyncThunk("tickets/fetchTicketData", async (userType, thunkAPI) => {
  const services = new Service();
  const endpoint = userType === "academy" ? "ticket/user/listview" : "ticket/listview";
  try {
    const response = await services.get(endpoint);
    return response.data.content;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Define the asynchronous thunk to fetch ticket types
export const fetchTicketType = createAsyncThunk("tickets/fetchTicketType", async (_, thunkAPI) => {
  const services = new Service();
  try {
    const response = await services.get("ticket/ticket-type/0");
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Define the asynchronous thunk to fetch ticket subtypes
export const fetchTicketSubType = createAsyncThunk("tickets/fetchTicketSubType", async (id, thunkAPI) => {
  const services = new Service();
  try {
    const response = await services.get(`ticket/ticket-type/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Create the tickets slice
const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTicketData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTicketData.fulfilled, (state, action) => {
        state.loading = false;
        state.ticketData = action.payload;
      })
      .addCase(fetchTicketData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(fetchTicketType.fulfilled, (state, action) => {
        state.ticketType = action.payload;
      })
      .addCase(fetchTicketType.rejected, (state, action) => {
        state.error = action.payload.message;
      })
      .addCase(fetchTicketSubType.fulfilled, (state, action) => {
        state.ticketSubType = action.payload;
      })
      .addCase(fetchTicketSubType.rejected, (state, action) => {
        state.error = action.payload.message;
      });
  },
});

export default ticketsSlice.reducer;
